import axios from "axios";

const apiValidacion = axios.create({
    baseURL: "https://sktarjetaclub.superkompras.com.mx/api/v1/validacion/",
    // "https://sktarjetaclub.superkompras.com.mx/api/v1/validacion/",
    // https://backendvalidaciontarjetaclub.superkompras.com.mx
    // https://deploysk.superkompras.com.mx/
    // http://localhost:5000/api/v1/validacion/
});

const apiUsuarios = axios.create({
    baseURL: "https://sktarjetaclub.superkompras.com.mx/api/v1/usuarios/",
    // baseURL: "https://sktarjetaclub.superkompras.com.mx/api/v1/usuarios/",
    // https://devs-devitm.com/api/v1/usuarios/
});

const apiComercio = axios.create({
    baseURL: "https://sktarjetaclub.superkompras.com.mx/api/v1/busquedaAlgolia",
});

export { apiValidacion, apiUsuarios, apiComercio }
